import React from 'react';
import { useTranslation } from 'react-i18next';

import { Panel } from 'components/containers';
import { OptionallyVisible } from 'components/optionallyVisible';

import { OrderSide } from 'types/order';

import { LABELS } from '../../keys';
import { OrderSectionProps } from '../../types';
import { AmountBlock, AmountNumericValue, AmountValue, Item, ItemName, ItemValue, SectionWrapper } from '../styles';
import { formatNumber } from '../utils';

export const ExchangeInUsdDetailsSection = ({ orderData }: OrderSectionProps) => {
  const { t } = useTranslation();

  const isBuyOrder = Boolean(orderData.order.side === OrderSide.Buy);

  return (
    <Panel label={t(LABELS.SECTIONS.EXCHANGE_DETAILS_IN_USD.TITLE)}>
      <SectionWrapper>
        <OptionallyVisible visible={Boolean(orderData.order?.fiatAmountInUSD)}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.EXCHANGE_DETAILS_IN_USD.FIAT_AMOUNT)}</ItemName>
            <ItemValue>
              <AmountBlock>
                <AmountValue>{orderData.executedQuote?.fiatCurrencyCode}</AmountValue>
                <AmountNumericValue>{formatNumber(orderData.order?.fiatAmountInUSD)}</AmountNumericValue>
              </AmountBlock>
            </ItemValue>
          </Item>
        </OptionallyVisible>

        <OptionallyVisible visible={Boolean(orderData.order?.processingFeeFiatAmountInUSD)}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.EXCHANGE_DETAILS_IN_USD.PROCESSING_FEE_AMOUNT)}</ItemName>
            <ItemValue>
              <AmountBlock>
                <AmountValue>{orderData.executedQuote?.fiatCurrencyCode}</AmountValue>
                <AmountNumericValue>{formatNumber(orderData.order?.processingFeeFiatAmountInUSD)}</AmountNumericValue>
              </AmountBlock>
            </ItemValue>
          </Item>
        </OptionallyVisible>

        <OptionallyVisible visible={isBuyOrder}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.EXCHANGE_DETAILS_IN_USD.QUOTED_NETWORK_FEE)}</ItemName>
            <ItemValue>
              <AmountBlock>
                <AmountValue>{orderData.executedQuote?.fiatCurrencyCode}</AmountValue>
                <AmountNumericValue>{formatNumber(orderData.executedQuote?.networkFeeFiatAmount)}</AmountNumericValue>
              </AmountBlock>
            </ItemValue>
          </Item>
        </OptionallyVisible>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.EXCHANGE_DETAILS_IN_USD.EXCHANGED_AMOUNT)}</ItemName>
          <ItemValue>
            <AmountBlock>
              <AmountValue>{orderData.executedQuote?.fiatCurrencyCode}</AmountValue>
              <AmountNumericValue>{formatNumber(orderData.executedQuote?.cryptoCostFiatAmount)}</AmountNumericValue>
            </AmountBlock>
          </ItemValue>
        </Item>
      </SectionWrapper>
    </Panel>
  );
};
