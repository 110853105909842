import React from 'react';

import OptionallyVisible from 'components/optionallyVisible';

import { OrderSide } from 'types/order';

import { CustomerSection } from './sections/customer/customer';
import { DepositSection } from './sections/deposit/deposit';
import { ExchangeDetailsSection } from './sections/exchangeDetails/exchangeDetails';
import { ExchangeInUsdDetailsSection } from './sections/exchangeInUsdDetails/exchangeInUsdDetails';
import { OrderSection } from './sections/order/order';
import { PaymentSection } from './sections/payment/payment';
import { TopAreaSection } from './sections/topArea/topArea';
import { WithdrawalSection } from './sections/withdrawal/withdrawal';
import { Container, SubContainer } from './styles';
import { OrderData } from './types';

export interface OrderDetailsProps {
  orderData: OrderData;
  shouldShowExchangeDetailsSection: boolean;
  shouldShowWithdrawalDetailsSection: boolean;
  hasExtendedAccess: boolean;
  shouldShowExtendedDepositDetails: boolean;
  shouldShowExchangeInUsdDetailsSection: boolean;
}

export const OrderDetails = ({
  orderData,
  shouldShowExchangeDetailsSection,
  shouldShowWithdrawalDetailsSection,
  hasExtendedAccess,
  shouldShowExtendedDepositDetails,
  shouldShowExchangeInUsdDetailsSection,
}: OrderDetailsProps) => {
  const sectionsProps = {
    orderData,
  };

  if (orderData.order.side === OrderSide.Sell) {
    return (
      <>
        <TopAreaSection {...sectionsProps} />
        <Container>
          <SubContainer>
            <OrderSection {...sectionsProps} />

            <OptionallyVisible visible={shouldShowExchangeDetailsSection}>
              <ExchangeDetailsSection {...sectionsProps} />
            </OptionallyVisible>

            <OptionallyVisible visible={shouldShowExchangeInUsdDetailsSection}>
              <ExchangeInUsdDetailsSection {...sectionsProps} />
            </OptionallyVisible>
          </SubContainer>

          <SubContainer>
            <CustomerSection {...sectionsProps} showCustomerLink={hasExtendedAccess} />
            <DepositSection {...sectionsProps} extended={shouldShowExtendedDepositDetails} />
            <PaymentSection {...sectionsProps} shouldShowPaymentSectionAdditionalFields={hasExtendedAccess} />
          </SubContainer>
        </Container>
      </>
    );
  }

  return (
    <>
      <TopAreaSection {...sectionsProps} />
      <Container>
        <SubContainer>
          <OrderSection {...sectionsProps} />

          <OptionallyVisible visible={shouldShowExchangeDetailsSection}>
            <ExchangeDetailsSection {...sectionsProps} />
          </OptionallyVisible>

          <OptionallyVisible visible={shouldShowExchangeInUsdDetailsSection}>
            <ExchangeInUsdDetailsSection {...sectionsProps} />
          </OptionallyVisible>
        </SubContainer>

        <SubContainer>
          <CustomerSection {...sectionsProps} showCustomerLink={hasExtendedAccess} />
          <PaymentSection {...sectionsProps} shouldShowPaymentSectionAdditionalFields={hasExtendedAccess} />

          <OptionallyVisible visible={shouldShowWithdrawalDetailsSection}>
            <WithdrawalSection {...sectionsProps} />
          </OptionallyVisible>
        </SubContainer>
      </Container>
    </>
  );
};
