import { t } from 'i18next';

import { DataTableColumn } from 'components/dataTable';

import RoutePath from 'router/path';

import { OrderStatus } from 'types/order';

export const KEYS = {
  FILTER_KEY: 'orders',
  ITEMS_PER_PAGE_OPTIONS: [15, 20, 50, 100],
  SEARCH_DEBOUNCE_TIMEOUT: 700,
  DATE_FORMAT: 'MMM DD, HH:mm A',
  DATE_FORMAT_WITH_YEAR: 'MMM DD, YYYY, HH:mm A',
  TRANSLATION_NAMESPACE: 'page.accountSettings',
  ROUTE_MATCH: `${RoutePath.AccountSettings.Root}/:route`,
  CRYPTO_SYMBOL_SEPARATOR: '.',
  CRYPTO_NETWORK_SPACING_SYMBOLS_EXP: /[_]/gi,
  TABS: {
    GENERAL: 'general',
    API_KEYS: 'api-keys',
    USER_MANAGEMENT: 'users',
  },
};

export const filterStatusGroups = {
  success: [OrderStatus.Completed],
  pending: [OrderStatus.AwaitingClientConfirmation, OrderStatus.Processing, OrderStatus.AwaitingDeposit, OrderStatus.Initiated],
  failed: [
    OrderStatus.AuthorizationChargeFailed,
    OrderStatus.GetQuoteToExecuteFailed,
    OrderStatus.ExecuteQuoteFailed,
    OrderStatus.CaptureChargeFailed,
    OrderStatus.SystemError,
    OrderStatus.ExchangeFailed,
    OrderStatus.PaymentFailed,
  ],
};

export const LABELS = {
  BREADCRUMBS: [['page.orders.breadcrumbs', 'Orders']],
  PAGE_TITLE: ['page.orders.list.title', 'Order list'],
  SEARCH_PLACEHOLDER: ['page.orders.list.search.placeholder', 'Search by Order ID, User or Account ID'],
  CSV_DOWNLOAD_ERROR: ['page.orders.list.csv.downloadError', 'Something went wrong, please try again later'],
  CSV_FILE_NAME: ['page.orders.list.csv.fileName', 'Orders export {{date}}.csv'],
  FILTERS: {
    SIDE: {
      LABEL: ['page.orders.list.filter.side.label', 'Type'],
      TOTAL: ['page.orders.list.filter.side.total', 'All Orders'],
      BUY: ['page.orders.list.filter.side.buy', 'Buy Orders'],
      SELL: ['page.orders.list.filter.side.sell', 'Sell Orders'],
    },
    STATUS: {
      LABEL: ['page.orders.list.filter.status.label', 'Status'],
      SUCCESS: ['page.orders.list.filter.status.success', 'Completed'],
      PENDING: ['page.orders.list.filter.status.pending', 'Processing'],
      FAILED: ['page.orders.list.filter.status.failed', 'Failed'],
    },
    DATE: {
      LABEL: ['page.orders.list.filter.date.label', 'Date'],
    },
    FIAT_CURRENCY: {
      LABEL: ['page.orders.list.filter.fiatCurrency.label', 'Fiat Currency'],
    },
    FIAT_AMOUNT: {
      LABEL: ['page.orders.list.filter.fiatAmount.label', 'Fiat Amount'],
    },
    CRYPTO_CURRENCY: {
      LABEL: ['page.orders.list.filter.cryptoCurrency.label', 'Cryptocurrency'],
    },
    CRYPTO_AMOUNT: {
      LABEL: ['page.orders.list.filter.cryptoAmount.label', 'Crypto Amount'],
    },
    PARTNER: {
      LABEL: ['page.orders.list.filter.partner.label', 'Partner Account'],
      PLACEHOLDER: ['page.orders.list.filter.partner.placeholder', 'Select one'],
    },
  },
  TABLE_COLUMNS: {
    STATUS: ['page.orders.list.table.columns.status', 'Status'],
    ID: ['page.orders.list.table.columns.id', 'Order'],
    DATE: ['page.orders.list.table.columns.date', 'Date'],
    USER: ['page.orders.list.table.columns.user', 'User'],
    FIAT_AMOUNT: ['page.orders.list.table.columns.fiatAmount', 'Fiat Amount'],
    SIDE: ['page.orders.list.table.columns.side', 'Type'],
    CRYPTO_AMOUNT: ['page.orders.list.table.columns.cryptoAmount', 'Crypto Amount'],
    ACTIONS: ['page.orders.list.table.columns.actions', ''],
  },
};

export const COLUMN_IDS = {
  STATUS: 'status',
  ID: 'uuid',
  DATE: 'createdAt',
  USER: 'userEmail',
  FIAT_AMOUNT: 'fiatAmount',
  SIDE: 'side',
  FIAT_CURRENCY: 'fiatCurrencyCode',
  CRYPTO_AMOUNT: 'cryptoAmount',
  CRYPTO_CURRENCY: 'cryptoCurrencyCode',
  ACTIONS: 'actions',
};

export const COLUMNS: DataTableColumn[] = [
  { id: COLUMN_IDS.STATUS, label: t(LABELS.TABLE_COLUMNS.STATUS) },
  { id: COLUMN_IDS.ID, label: t(LABELS.TABLE_COLUMNS.ID), sortable: true },
  { id: COLUMN_IDS.DATE, label: t(LABELS.TABLE_COLUMNS.DATE), sortable: true },
  { id: COLUMN_IDS.USER, label: t(LABELS.TABLE_COLUMNS.USER) },
  { id: COLUMN_IDS.FIAT_AMOUNT, label: t(LABELS.TABLE_COLUMNS.FIAT_AMOUNT), sortable: true },
  { id: COLUMN_IDS.SIDE, label: t(LABELS.TABLE_COLUMNS.SIDE) },
  { id: COLUMN_IDS.CRYPTO_AMOUNT, label: t(LABELS.TABLE_COLUMNS.CRYPTO_AMOUNT), sortable: true },
  { id: COLUMN_IDS.ACTIONS, label: t(LABELS.TABLE_COLUMNS.ACTIONS) },
];
