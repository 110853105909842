import { OptionallyVisible } from 'components';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Button } from 'components/buttons';
import { Panel } from 'components/containers';

import { AccountSettings } from 'types/account';

import { GeneralAccountSettingForm } from './form/form';
import { LABELS } from './keys';
import { Controls, FormContainer } from './styles';

interface GeneralSectionProps extends WithTranslation {
  account: AccountSettings;
  isFormChanged: boolean;
  isSaveAvailable: boolean;
  resetFormValues: () => void;
  saveFormValues: () => void;
  onFormValueChange: (key: string, value: any) => void;
  disabledFields: Record<string, boolean>;
  isUpdating: boolean;
  error?: Record<string, string>;
  readOnlyMode: boolean;
}

export const GeneralSectionComponent = ({
  t,
  account,
  isSaveAvailable,
  isFormChanged,
  resetFormValues,
  onFormValueChange,
  saveFormValues,
  disabledFields,
  isUpdating,
  error,
  readOnlyMode,
}: GeneralSectionProps) => {
  return (
    <Panel
      label={t(LABELS.PANEL_TITLE)}
      controls={
        readOnlyMode ? undefined : (
          <Controls>
            <OptionallyVisible visible={isFormChanged}>
              <Button onClick={resetFormValues} secondary disabled={isUpdating}>
                {t(LABELS.CANCEL_BUTTON_LABEL)}
              </Button>
            </OptionallyVisible>
            <Button onClick={saveFormValues} primary disabled={!isSaveAvailable || isUpdating}>
              {t(LABELS.SAVE_BUTTON_LABEL)}
            </Button>
          </Controls>
        )
      }
    >
      <FormContainer>
        <GeneralAccountSettingForm
          account={account}
          onFieldValueChange={onFormValueChange}
          disabledFields={disabledFields}
          isUpdating={isUpdating}
          error={error}
          readOnlyMode={readOnlyMode}
        />
      </FormContainer>
    </Panel>
  );
};

export const GeneralSection = withTranslation()(GeneralSectionComponent);
