import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useUpdateAccountMutation } from 'services/accounts';

import selectAccount from 'state/selectors/accounts/accountSelector';
import { selectUserLevel } from 'state/slices/userSlice';

import { AccountSettings } from 'types/account';
import { ValidationErrorMeta } from 'types/error';
import { UserAccessLevel } from 'types/user';

import { getValidationDetails } from 'utils/error';

import { getFormValues } from '../../utils';
import { GeneralSection } from './general';
import { formFields } from './keys';

export const GeneralSectionContainer = () => {
  const { account } = useSelector(selectAccount);
  const accountData = getFormValues(account, formFields) as AccountSettings;
  const [values, setValues] = useState<AccountSettings>(accountData);
  const [saveAccountInfo, { isLoading: isUpdating, error, reset }] = useUpdateAccountMutation();
  const userLevel = useSelector(selectUserLevel);

  useEffect(() => {
    setValues(getFormValues(account, formFields) as AccountSettings);
  }, [account.uuid]);

  const handleFormValueChange = (key: string, value: any) => {
    setValues({
      ...values,
      [key]: value,
    });
    reset();
  };

  const isAdmin = Boolean(userLevel && userLevel >= UserAccessLevel.Admin);
  const disabledFields = {
    isCertified: !isAdmin,
    processingFee: !isAdmin,
    onRampEnabled: !isAdmin,
    offRampEnabled: !isAdmin,
  };

  const readOnlyMode = Boolean(userLevel && userLevel === UserAccessLevel.Support);

  const resetFormValues = () => {
    setValues(getFormValues(account, formFields) as AccountSettings);
    reset();
  };

  const saveFormValues = async () => {
    if (readOnlyMode) {
      return;
    }

    const disabledFieldKeys = Object.entries(disabledFields)
      .filter(([, value]) => Boolean(value))
      .map(([key]) => key);

    const diffValues = (Object.keys(values) as (keyof AccountSettings)[])
      .filter((key) => account[key] !== values[key])
      .reduce((result, key) => ({ ...result, [key]: values[key] }), { uuid: account.uuid });

    const preparedValues = omit(diffValues, disabledFieldKeys);
    saveAccountInfo(preparedValues);
  };

  const isFormChanged = !isEqual(values, accountData);
  const saveErrorMeta = error ? ((error as FetchBaseQueryError).data as { meta: ValidationErrorMeta }).meta : undefined;

  const saveError = saveErrorMeta ? getValidationDetails(saveErrorMeta) : undefined;
  const isSaveAvailable = isFormChanged && isEmpty(saveError);

  return (
    <GeneralSection
      account={values}
      onFormValueChange={handleFormValueChange}
      isFormChanged={isFormChanged}
      isSaveAvailable={isSaveAvailable}
      resetFormValues={resetFormValues}
      saveFormValues={saveFormValues}
      disabledFields={disabledFields}
      error={saveError}
      isUpdating={isUpdating}
      readOnlyMode={readOnlyMode}
    />
  );
};
