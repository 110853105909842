import { UserRoleType } from './user';

export enum AccountAccessLevel {
  Owner = 'owner',
  Administrator = 'administrator',
  Viewer = 'viewer',
}

export enum AccountInviteStatus {
  Pending = 'pending',
  Accepted = 'accepted',
  Revoked = 'revoked',
}

export interface Account {
  owner: AccountOwner;
  access: AccountAccessInfo;
  account: AccountInfo;
}

export type PartnerAccountInfo = AccountInfo;

export interface AccountOwner {
  uuid: string;
  username: string;
  firstName: string;
  lastName: string;
  roles: UserRoleType[];
  signupAt: string;
}

export interface AccountAccessInfo {
  level: AccountAccessLevel;
  grantedAt: string;
  inviteStatus: AccountInviteStatus;
  inviteStatusUpdatedAt: string;
}

export enum AccountEmbeddableThemeMode {
  Light = 'light',
  Dark = 'dark',
  SameAsBrowser = 'sameAsBrowser',
}

export interface AccountInfo extends AccountSettings, AccountLandingPageSettings, AccountWidgetSettings {
  cryptoExchangeSpread: {};
  defaultAccountUuid?: string;
}

export interface AccountSettings {
  uuid: string;
  webhookUrl: string;
  name: string;
  processingFee: number;
  isCertified: boolean;
  onRampEnabled: boolean;
  offRampEnabled: boolean;
}

export interface AccountLandingPageSettings {
  landingPageEnabled: boolean;
  urlSlug?: string;
  pageTitle?: string;
  enableRedirectUrl: boolean;
  orderCompleteRedirectUrl?: string;
  pageColor: string;
  pageThemeMode: AccountEmbeddableThemeMode;
  landingLogo: File | string;
  landingLogoDark: File | string;
  redirectDomain: string;
}

export interface AccountWidgetSettings {
  widgetColor: string;
  widgetThemeMode: AccountEmbeddableThemeMode;
  cornerRadius?: number;
  displayIllustration: boolean;
  defaultCryptoCurrencyCode: string;
  supportedCryptoCurrenciesCodes?: string[];
  supportedFiatCurrenciesCodes?: string[];
  popularCryptoCurrenciesCodes?: string[];
  widgetLogo: File | string;
  widgetLogoDark: File | string;
}

export interface ApplePayDomainDescription {
  uuid: string;
  accountUuid: string;
  domain: string;
  type: 'apple_pay';
  euDomainId: string;
  usDomainId: string;
}
